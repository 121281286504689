import { createStore } from 'vuex'

import state from './state'
import mutations from './mutations'

// Modules
import combinations from './modules/combinations'
import pac from './modules/pac'
import productModel from './modules/product-model'
import search from './modules/search'
import validations from './modules/validations'
import ruleValidation from './modules/rule-validation'

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state,
  mutations,
  modules: {
    combinations,
    pac,
    search,
    validations,
    'product-model': productModel,
    'rule-validation': ruleValidation
  }
})
